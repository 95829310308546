// import Vue from 'vue'~/pages/
import { createRouter, createWebHistory } from 'vue-router/auto'
import { handleHotUpdate, routes } from 'vue-router/auto-routes'
import { setupLayouts } from 'virtual:generated-layouts'

import { getCurrentUser } from 'vuefire'

//
export const routerHistory = createWebHistory(import.meta.env.BASE_URL)

const router = createRouter({
  history: routerHistory,
  strict: true,
  routes: setupLayouts(routes),
})

if (import.meta.hot) {
  handleHotUpdate(router)
}

router.beforeEach(async (to, from) => {
  // routes with `meta: { requiresAuth: true }` will check for
  // the users, others won't
  if (to.meta.anonymous) {
    return true
  }
  else {
    const currentUser = await getCurrentUser()
    // if the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: '/login',
        query: {
          // we keep the current path in the query so we can
          // redirect to it after login with
          // `router.push(route.query.redirect || '/')`
          redirect: to.name === '/logout/' ? '/' : to.fullPath,
        },
      }
    }

    if (to.meta.requiresAdmin) {
      if (currentUser.email !== 'phill@postmaker.io') {
        return {
          path: from.path,
        }
      }
    }
  }
})

export default router
