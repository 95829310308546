export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('E:/Projects/PostMaker/postmaker/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: '',
        name: '/admin/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/admin/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/brands',
    /* internal name: '/brands' */
    /* no component */
    children: [
      {
        path: '',
        name: '/brands/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/index.vue'),
        /* no children */
      },
      {
        path: ':brandId',
        name: '/brands/[brandId]',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/[brandId].vue'),
        children: [
          {
            path: '',
            name: '/brands/[brandId]/',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/[brandId]/index.vue'),
            /* no children */
          },
          {
            path: 'settings',
            /* internal name: '/brands/[brandId]/settings' */
            /* no component */
            children: [
              {
                path: '',
                name: '/brands/[brandId]/settings/',
                component: () => import('E:/Projects/PostMaker/postmaker/src/pages/brands/[brandId]/settings/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/campaigns',
    /* internal name: '/campaigns' */
    /* no component */
    children: [
      {
        path: '',
        name: '/campaigns/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/index.vue'),
        /* no children */
      },
      {
        path: ':campaignId',
        name: '/campaigns/[campaignId]',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId].vue'),
        children: [
          {
            path: '',
            name: '/campaigns/[campaignId]/',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId]/index.vue'),
            /* no children */
          },
          {
            path: ':variationId',
            name: '/campaigns/[campaignId]/[variationId]',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId]/[variationId].vue'),
            /* no children */
          },
          {
            path: 'generate',
            name: '/campaigns/[campaignId]/generate',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId]/generate.vue'),
            /* no children */
          },
          {
            path: 'inspiration',
            name: '/campaigns/[campaignId]/inspiration',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId]/inspiration.vue'),
            /* no children */
          },
          {
            path: 'schedule',
            name: '/campaigns/[campaignId]/schedule',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId]/schedule.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: '/campaigns/[campaignId]/settings',
            component: () => import('E:/Projects/PostMaker/postmaker/src/pages/campaigns/[campaignId]/settings.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/login',
    /* internal name: '/login' */
    /* no component */
    children: [
      {
        path: '',
        name: '/login/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/login/index.vue'),
        /* no children */
        meta: {
          "layout": "none",
          "anonymous": true
        }
      }
    ],
  },
  {
    path: '/logout',
    /* internal name: '/logout' */
    /* no component */
    children: [
      {
        path: '',
        name: '/logout/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/logout/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/posts',
    /* internal name: '/posts' */
    /* no component */
    children: [
      {
        path: '',
        name: '/posts/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/posts/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/profile',
    /* internal name: '/profile' */
    /* no component */
    children: [
      {
        path: '',
        name: '/profile/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/profile/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/team',
    /* internal name: '/team' */
    /* no component */
    children: [
      {
        path: '',
        name: '/team/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/team/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/variables',
    /* internal name: '/variables' */
    /* no component */
    children: [
      {
        path: '',
        name: '/variables/',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/variables/index.vue'),
        /* no children */
      },
      {
        path: ':variableId',
        name: '/variables/[variableId]',
        component: () => import('E:/Projects/PostMaker/postmaker/src/pages/variables/[variableId].vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

