import { createApp } from 'vue'
import { createPinia } from 'pinia'
import type { LogArgument } from 'rollbar'
import Rollbar from 'rollbar'
import { VueFire, VueFireAuth } from 'vuefire'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { version } from '../package.json'
import App from './App.vue'
import router from './routes'

import './assets/styles/main.css'

import { firebaseApp } from '~/fb/init.js'

const isProd = import.meta.env.PROD

const rollbar = new Rollbar({
  accessToken: '64f37f7bc964447d9e377d35c18cbe8c',
  captureUncaught: isProd,
  enabled: isProd,
  captureUnhandledRejections: isProd,
  environment: import.meta.env.MODE,
  payload: {
    environment: import.meta.env.MODE,
    code_version: version,
  },
})

if (import.meta.env.DEV) {
  useStorage('umami.disabled', 'true')
}

const app = createApp(App)

app.config.globalProperties.window = window

app.use(VueFire, {
  // imported above but could also just be created here
  firebaseApp,
  modules: [
    // we will see other modules later on
    VueFireAuth(),
  ],
})
app.use(router)
app.use(createPinia())
app.use(autoAnimatePlugin)

if (isProd) {
  app.provide('rollbar', rollbar)

  app.config.errorHandler = (err) => {
    rollbar.error(err as LogArgument)
    throw err // rethrow
  }
}

app.mount('#app')
